<template>
  <div class="content">
    <KTCard title="Select route permissions">
      <template v-slot:body>
        <b-row class="mb-3">
          <b-col lg="4">
            <!-- Select route permission -->
            <b-form-select
              v-model="selected"
              @change="handlePermissionChange()"
            >
              <b-form-select-option value="null" disabled
                >--Select a permission--</b-form-select-option
              >
              <b-form-select-option
                v-for="(permission, index) in RoutePermission"
                :key="index"
                :value="index"
              >
                {{ permission.as }}
              </b-form-select-option>
            </b-form-select>

            <!-- END Select route permission -->
          </b-col>
        </b-row>
      </template>
    </KTCard>

    <KTCard title="Permissions">
      <template v-slot:toolbar>
        <b-button class="mr-2" @click="addPermission()" variant="info" size="sm"
          ><i class="fa fa-plus"></i> Add
        </b-button>
        <b-button @click="handleSave()" variant="primary" size="sm"
          ><i class="fa fa-save"></i> Save
        </b-button>
      </template>

      <template v-slot:body>
        <div v-if="permissions.length">
          <b-row
            v-for="(permission, index) in permissions"
            :key="index"
            class="mb-3"
            align-v="center"
          >
            <b-col>
              <label>System Type</label>
              <b-form-select
                class="form-control"
                v-model="permission.system_type_id"
                :options="systemOptions"
              ></b-form-select>
            </b-col>
            <b-col>
              <label>Module</label>
              <b-form-select
                class="form-control"
                v-model="permission.module_id"
                :options="moduleOptions(permission.system_type_id)"
              ></b-form-select>
            </b-col>
            <b-col>
              <label>Right</label>
              <b-form-select
                class="form-control"
                v-model="permission.right_id"
                :options="right"
              ></b-form-select>
            </b-col>
            <b-col align-self="end" class="col-auto">
              <b-button-group class="pb-1" size="sm">
                <b-button
                  @click="removePermission(index)"
                  variant="alt-danger"
                  size="sm"
                >
                  <i class="fa fa-times"></i>
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </div>
        <div v-else class="mb-3">
          <em>No permissions available</em>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import {
  GET_ROUTE_PERMISSION,
  UPDATE_ROUTE_PERMISSION,
} from "@/core/services/store/modules/superadmin/administration.module";

export default {
  data() {
    return {
      selected: null,
      fields: [
        { key: "as", label: "Permissions" },
        { key: "system_type_id", label: "System" },
        { key: "module_id", label: "Modules" },
        { key: "right_id", label: "Rights" },
      ],
      systemOptions: [
        { value: 1, text: "User" },
        { value: 2, text: "Admin" },
      ],
      module: {
        customer: [
          { value: 1, text: "GENERAL" },
          { value: 2, text: "EMPLOYEE_REGISTRATION" },
          { value: 3, text: "SALARY_DISTRIBUTION" },
          //   { value: 4, text: "COMPANY" }
        ],
        admin: [
          { value: 1, text: "GENERAL" },
          { value: 2, text: "USERS" },
        ],
      },
      right: [
        { value: 1, text: "READ" },
        { value: 2, text: "EDIT" },
        { value: 3, text: "CREATE" },
        { value: 4, text: "DELETE" },
        { value: 5, text: "APPROVE" },
        { value: 6, text: "MAKER" },
        { value: 7, text: "CHECKER" },
      ],
      permissions: [],
    };
  },
  computed: {
    RoutePermission() {
      const permission =
        this.$store.state.superadmin_administration.routePermission;
      console.log("RoutePermission ", permission);
      if (Array.isArray(permission) && permission.length) {
        return permission;
      } else {
        return [];
      }
    },
    SelectedPermission() {
      console.log(
        "SelectedPermission ",
        this.RoutePermission[this.selected].permissions
      );
      const permissions = this.RoutePermission[this.selected].permissions;
      if (Array.isArray(permissions) && permissions.length) {
        return permissions;
      } else {
        return [];
      }
    },
    // CustomerPermission() {
    //   const list =
    //     this.$store.state.ui.systemConstants.CustomerPermissionReference;
    //   if (list !== undefined) {
    //     return list;
    //   } else {
    //     return {};
    //   }
    // },
    // AdminPermission() {
    //   const list =
    //     this.$store.state.ui.systemConstants.AdminPermissionReference;
    //   if (list !== undefined) {
    //     return list;
    //   } else {
    //     return {};
    //   }
    // },
  },
  methods: {
    handlePermissionChange() {
      console.log("change");
      this.permissions = [];
      this.SelectedPermission.forEach((permission) => {
        this.permissions.push(permission);
        console.log(this.permissions);
      });
    },
    handleSave() {
      // update route permissions
      const payload = {
        routePermissionList: [
          {
            as: this.RoutePermission[this.selected].as,
            permissions: this.permissions,
          },
        ],
      };

      this.$store.dispatch(UPDATE_ROUTE_PERMISSION, payload).then((resp) => {
        if (resp.response_code === 2100) {
          console.log("UPDATE_ROUTE_PERMISSION SUCCESS");
        } else {
          console.log("UPDATE_ROUTE_PERMISSION FAILED");
        }
      });
    },
    addPermission() {
      this.permissions.push({
        system_type_id: 1,
        module_id: 1,
        right_id: 1,
      });
    },
    removePermission(index) {
      this.permissions.splice(index, 1);
    },
    moduleOptions(id) {
      if (id == "1") {
        return this.module.customer;
      } else if (id == "2") {
        return this.module.admin;
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Superadmin" },
      { title: "Route Permission" },
    ]);

    // system constants
    // this.$store.dispatch(SYSTEM_CONSTANTS).then((res) => {
    //   const data = res.data;
    //   if (data.response_code == 2100) {
    //     this.module.customer = [];
    //     Object.keys(this.CustomerPermission).forEach((permission) => {
    //       this.module.customer.push({
    //         text: permission,
    //         value: this.CustomerPermission[permission],
    //       });
    //     });
    //
    //     this.module.admin = [];
    //     Object.keys(this.AdminPermission).forEach((permission) => {
    //       this.module.admin.push({
    //         text: permission,
    //         value: this.AdminPermission[permission],
    //       });
    //     });
    //   }
    // });

    // get route permissions
    this.$store.dispatch(GET_ROUTE_PERMISSION).then(() => {});
  },
  // beforeDestroy() {
  //   this.$store.dispatch(RESET_BREADCRUMB);
  // },
};
</script>
